import React from "react";
import { connect } from "react-redux";
import { Link, browserHistory } from "react-router";
import { toastr } from "react-redux-toastr";
import _ from "lodash";
import fileDownload from "js-file-download";
import Icon from "react-icons-kit";
import { drawer2 } from "react-icons-kit/icomoon/drawer2";
import ContractLog from "./ContractLog";

import MaskDecimal from "../../components/Util/MaskDecimal";
import MaskDate from "../../components/Util/MaskDate";
import MaskCNPJ from "../../components/Util/MaskCNPJ";
import { BeatLoader } from "react-spinners";
import Title from "../../components/Layout/Title";
import { logoEspelhoBase64 } from "../../img/logoEspelhoBase64";

import {
  CONFIRM_OPERATION_OPEN,
  CONFIRM_ACTION_OPEN,
  CONTRACT_VIEW_COMPANY_LOAD,
  CONTRACT_EDIT_LOAD,
  CONTRACT_EDIT_UNLOAD,
  CONTRACT_EDIT_COMPANIES_LOAD,
  CONTRACT_EDIT_CONTRACT_LOAD,
  CONTRACT_EDIT_VEHICLE_ADD,
  CONTRACT_EDIT_VEHICLE_LOAD,
  CONTRACT_EDIT_LOG_LOAD,
  CONTRACT_EDIT_LAST_LOG_LOAD,
  CONTRACT_EDIT_VEHICLE_SELECTED,
  CONTRACT_EDIT_COMPANY_ERROR,
  CONTRACT_ADDITIVE_CREATED,
  CONFIRM_NOT_HOMOLOGATE_OPEN,
  CONTRACT_EDIT_ERR_MSG_LOAD,
  SHOW_AVERBACAO,
  CONTRACT_HOMOLOGATED,
  CONTRACT_NOT_HOMOLOGATED,
  CONTRACT_EDIT_UPLOAD,
  CONTRACT_CANCEL_SUCCESS,
  CONTRACT_SHELVE_SUCCESS,
  CONTRACT_COMUNICA_DETRAN,
  LOGOUT,
  CONTRACT_CANCEL_ALIAS,
  CONTRACT_BAIXA_ALIAS,
} from "../../actions/Constants";
import api from "../../api/apiUtil";
import Dropzone from "react-dropzone";
import jsPDF from "jspdf";
import { filePdf } from "react-icons-kit/icomoon/filePdf";
import SvgIcon from "react-icons-kit";
import { image } from "react-icons-kit/icomoon/image";
import OtherUtils from "../../components/Util/OtherUtils";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
class ContractView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledBtnEspelho: false,
      idAverbacaoCriada: null,
    };
  }

  handleOnPrintClick = () => (ev) => {
    ev.preventDefault();

    var content = document.getElementById("toPrint").innerHTML;
    var mywindow = window.open("", "Print", "height=600,width=800");

    mywindow.document.write("<html><head><title>eRegistro</title>");
    mywindow.document.write("</head><body >");
    mywindow.document.write(content);
    mywindow.document.write("</body></html>");

    mywindow.document.close();
    mywindow.focus();
    mywindow.print();
    mywindow.close();
  };

  componentWillMount() {
    if (this.props.params.slug) {
      Promise.resolve(api.Contracts.get(this.props.params.slug)).then((contract) => {
        delete contract.nomeImagem;
        this.props.onContractLoad(contract);
        if (contract.status === 90 || (contract.status === 0 && contract.uf === "BA")) {
          Promise.resolve(api.Contracts.lastMessage(contract.id)).then((msg) => {
            if (msg.length > 0) {
              this.props.onErrLoad(msg[0].retorno);
              if (msg && msg[0] && msg[0].contratoJaRegistrado) {
                if (this.props.limitVehicles) {
                  Promise.resolve(api.Contracts.vehicles(contract.id)).then((vs) => {
                    Promise.resolve(api.Contracts.getByChassi(vs[0].chassi)).then((cs) => {
                      var showAverbacaoError = true;
                      cs.forEach(function (item, index) {
                        if (item.status !== 90 && item.status !== 1 && item.status !== 91) {
                          showAverbacaoError = false;
                        }
                      });
                      if (showAverbacaoError) {
                        this.props.onShowAverbacaoError();
                      }
                    });
                  });
                } else {
                  Promise.resolve(api.Contracts.getByNumber(contract.numero, contract.uf)).then((cs) => {
                    var showAverbacaoError = true;
                    cs.forEach(function (item, index) {
                      if (item.status !== 90 && item.status !== 1 && item.status !== 91) {
                        showAverbacaoError = false;
                      }
                    });
                    if (showAverbacaoError) {
                      this.props.onShowAverbacaoError();
                    }
                  });
                }
              }
            }
          });
        }
        if (contract.idFinanceira) {
          this.props.onCompanyLoad(api.Companies.get(contract.idFinanceira));
        }
        if (contract.uf !== "BA" || contract.uf !== "MA") {
          Promise.resolve(api.Contracts.averbacaoCriada(contract.id)).then((resAverbacao) => {
            if (resAverbacao) {
              this.setState({ idAverbacaoCriada: resAverbacao.id });
            }
          });
        }
      });
      this.props.onVehiclesLoad(api.Contracts.vehicles(this.props.params.slug));
      this.props.onLogLoad(api.Contracts.log(this.props.params.slug));
      this.props.onLastLogLoad(api.Contracts.lastLog(this.props.params.slug));
      this.props.onComunicaDetranLoad(api.Contracts.comunicaDetran(this.props.params.slug));
    }

    this.props.onLoad();
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  handleCreateAdditive = () => (ev) => {
    ev.preventDefault();
    this.props.onActionOpen({ description: "Deseja criar uma averbação para o contrato?", onSuccess: this.createAdditive.bind(this) });
  };

  createAdditive() {
    if (this.state.idAverbacaoCriada) {
      this.props.onAdditiveCreated(this.state.idAverbacaoCriada);
    } else {
      var props = this.props;

      var dados = {
        dados: {
          contrato: this.props.contract,
          veiculos: this.props.vehicles,
        },
      };
      Promise.resolve(api.Contracts.additive(dados))
        .then((ret) => {
          toastr.success("Sucesso", "Averbação criada");
          this.props.onAdditiveCreated(ret.id);
        })
        .catch(function (resp) {
          if (resp === "Unauthorized" || resp === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    }
  }

  handleSendChanges = () => (ev) => {
    ev.preventDefault();
    this.props.onActionOpen({ description: "Deseja enviar uma alteração para o DETRAN?", onSuccess: this.sendChanges.bind(this) });
  };

  sendChanges() {
    var props = this.props;

    var dados = {
      dados: {
        contrato: this.props.contract,
        veiculos: this.props.vehicles,
      },
    };
    Promise.resolve(api.Contracts.change(dados))
      .then((ret) => {
        toastr.success("Operação realizada", "O contrato foi alterado!");
        this.props.onContractShelveSuccess();
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  handleClickImage = () => (ev) => {
    ev.preventDefault();
    this.setState({ disabledBtnEspelho: true });
    toastr.info("Aguarde", "Processando arquivo...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    Promise.resolve(api.Contracts.getImage(this.props.contract.id))
      .then((response) => {
        this.setState({ disabledBtnEspelho: false });
        toastr.removeByType("info");
        fileDownload(response, this.props.contract.numRegistro + ".pdf");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleClickAprove = () => (ev) => {
    ev.preventDefault();
    this.props.onOpen({ description: "Homologar o registro do contrato", onSuccess: this.onAproveSuccess.bind(this) });
  };

  handleClickNotAprove = () => (ev) => {
    ev.preventDefault();
    this.props.onOpenNotHomologate({ description: "Não homologar o registro do contrato", onSuccess: this.onDisaproveSuccess.bind(this) });
  };

  onAproveSuccess() {
    var props = this.props;
    Promise.resolve(api.Contracts.aprove(this.props.contract.id))
      .then((e) => {
        Promise.resolve(api.Contracts.get(this.props.contract.id)).then((response) => {
          //this.props.onContractLoad(api.Contracts.get(this.props.contract.id));
          this.props.onHomologated();
          toastr.success("Operação realizada", "Registro homologado!");
        });
      })
      .catch((e) => {
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  handleClickUpload = (filesToUpload) => {
    var props = this.props;
    var onUploading = this.props.onUploading;
    var onContractLoad = this.props.onContractLoad;
    var onVehiclesLoad = this.props.onVehiclesLoad;
    var onLogLoad = this.props.onLogLoad;
    var contractId = this.props.contract.id;
    this.props.onOpen({
      description: "Alterar a imagem do contrato ao DETRAN.",
      onSuccess: function () {
        onUploading(true);
        toastr.info("Aguarde", "Processando imagem...", {
          timeOut: 0,
          component: () => {
            return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
          },
        });
        Promise.resolve(api.Contracts.updateImages(contractId, filesToUpload))
          .then((response) => {
            onUploading(false);
            onContractLoad(api.Contracts.get(contractId));
            onVehiclesLoad(api.Contracts.vehicles(contractId));
            onLogLoad(api.Contracts.log(contractId));
            toastr.removeByType("info");
            toastr.success("Sucesso", "Imagem alterada.");
          })
          .catch(function (resp) {
            if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
              api.Auth.logout();
              props.onLogout();
            } else {
              toastr.removeByType("info");
              toastr.error("Erro", resp.response.body.message);
            }
            onUploading(false);
          });
      },
    });
  };

  handleOnCancelContract = () => (ev) => {
    ev.preventDefault();
    this.props.onOpen({
      description: this.props.contract.uf === "BA" ? "Realizar o cancelamento do contrato." : "Esta operação fará apenas a alteração da situação do contrato na base do E-Registro. Deseja prosseguir?",
      onSuccess: this.cancelContract.bind(this),
    });
  };

  handleOnCancelContractAlias = () => (ev) => {
    ev.preventDefault();
    this.props.onActionOpen({
      description: this.props.contract.uf === "BA" ? "Realizar o cancelamento do contrato." : "Esta operação fará apenas a alteração da situação do contrato na base do E-Registro. Deseja prosseguir?",
      onSuccess: this.cancelContractAlias.bind(this),
      justification: true,
    });
  };

  cancelContractAlias(params) {
    var props = this.props;
    Promise.resolve(api.Contracts.cancelContract({ id: this.props.contract.id, justificativa: params.justificativa }))
      .then((ret) => {
        toastr.success("Sucesso", "Cancelamento enviado com sucesso!");
        this.props.onCancel();
      })
      .catch((ret) => {
        if (ret.message === "Unauthorized" || ret.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  cancelContract() {
    var props = this.props;
    Promise.resolve(api.Contracts.cancelContract(this.props.contract.id))
      .then((ret) => {
        toastr.success("Sucesso", "Cancelamento enviado com sucesso!");
        this.props.onContractCancelSuccess();
      })
      .catch((ret) => {
        if (ret.message === "Unauthorized" || ret.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  handleOnShelveContractAlias = () => (ev) => {
    ev.preventDefault();
    this.props.onActionOpen({
      description: this.props.contract.uf === "BA" ? "Realizar a baixa do contrato." : "Esta operação fará apenas a alteração da situação do contrato na base do E-Registro. Deseja prosseguir?",
      onSuccess: this.shelveContractAlias.bind(this),
      justification: true,
    });
  };

  shelveContractAlias(params) {
    var props = this.props;
    Promise.resolve(api.Contracts.shelveContract({ id: this.props.contract.id, justificativa: params.justificativa }))
      .then((ret) => {
        toastr.success("Sucesso", "Baixa enviada com sucesso!");
        this.props.onBaixa();
      })
      .catch((ret) => {
        if (ret.message === "Unauthorized" || ret.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else if (ret.response && ret.response.body && ret.response.body.message) {
          toastr.error("Erro", ret.response.body.message);
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  handleOnShelveContract = () => (ev) => {
    ev.preventDefault();
    this.props.onOpen({
      description: this.props.contract.uf === "BA" ? "Realizar a baixa do contrato." : "Esta operação fará apenas a alteração da situação do contrato na base do E-Registro. Deseja prosseguir?",
      onSuccess: this.shelveContract.bind(this),
    });
  };

  shelveContract() {
    var props = this.props;
    Promise.resolve(api.Contracts.shelveContract(this.props.contract.id))
      .then((ret) => {
        toastr.success("Sucesso", "Baixa enviada com sucesso!");
        this.props.onContractShelveSuccess();
      })
      .catch((ret) => {
        if (ret.message === "Unauthorized" || ret.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  statusColor() {
    if (this.props.contract) {
      if (this.props.contract.status === 0 || this.props.contract.status === 1 || this.props.contract.status === 8) {
        return "hpanel hblue";
      }
      if (this.props.contract.status === 2) {
        return "hpanel hyellow";
      }
      if (this.props.contract.status === 3) {
        return "hpanel hgreen";
      }
      if (this.props.contract.status === 6) {
        return "hpanel hviolet";
      }
      if (this.props.contract.status === 90 || this.props.contract.status === 10) {
        return "hpanel hred";
      }
      return "hpanel";
    } else {
      return "hpanel hblue";
    }
  }

  onDisaproveSuccess(description) {
    var props = this.props;
    var reprove = {
      idContrato: this.props.contract.id,
      justificativa: description,
    };

    Promise.resolve(api.Contracts.reprove(reprove))
      .then((e) => {
        Promise.resolve(api.Contracts.get(this.props.contract.id)).then((response) => {
          //this.props.onContractLoad(api.Contracts.get(this.props.contract.id));
          this.props.onNotHomologated();
          toastr.success("Operação realizada", "Registro reprovado!");
        });
      })
      .catch((e) => {
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  statusColor() {
    if (this.props.contrato) {
      return "hpanel";
    } else {
      return "hpanel hviolet";
    }
  }

  renderAlienacaoFiduciariaFinanciamentoOuReservaDeDominioOuPenhor() {
    if (this.props.contract.tipoContrato == 1 || this.props.contract.tipoContrato == 3 || this.props.contract.tipoContrato == 5 || this.props.contract.tipoContrato == 6) {
      return (
        <div className="row m-t">
          <div className="col-md-6">
            <table className="table table-striped text-right">
              <tbody>
                <tr>
                  <td>
                    <strong>Taxa juros ao mês</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.taxaJurosMes)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Taxa de multa</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.taxaMulta)}</td>
                </tr>
                {this.props.contract.uf && this.props.contract.uf === "MG" && (
                  <tr>
                    <td>
                      <strong>Correção monetária</strong>
                    </td>
                    <td>{MaskDecimal.TO_BR(this.props.contract.taxaCorrecaoMonetaria)}</td>
                  </tr>
                )}
                <tr>
                  <td>
                    <strong>Valor do IOF</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.valorIOF)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Quantidade de parcelas</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.qtdeParcelas)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Data primeiro vencimento</strong>
                  </td>
                  <td>{MaskDate.TO_BR(this.props.contract.dataPrimeiroVenc)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>UF liberação do crédito</strong>
                  </td>
                  <td>{this.props.contract.ufLiberacaoCred}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Data liberação crédito</strong>
                  </td>
                  <td>{MaskDate.TO_BR(this.props.contract.dataLiberacaoCred)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Texto da penalidade</strong>
                  </td>
                  <td>{this.props.contract.penalidade}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Número aditivo contrato</strong>
                  </td>
                  <td>{this.props.contract.numeroAditivo}</td>
                </tr>
                <tr>
                  <td>
                    <strong>IPOC</strong>
                  </td>
                  <td>{this.props.contract.ipoc}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6">
            <table className="table table-striped text-right">
              <tbody>
                <tr>
                  <td>
                    <strong>Taxa juros ao ano</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.taxaJurosAno)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Taxa de mora</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.taxaMora)}</td>
                </tr>
                {this.props.contract.uf && this.props.contract.uf === "MG" && (
                  <tr>
                    <td>
                      <strong>
                        <br></br>
                      </strong>
                    </td>
                    <td></td>
                  </tr>
                )}
                <tr>
                  <td>
                    <strong>{this.props.contract.uf && this.props.contract.uf === "MG" ? "Valor da dívida" : "Valor total do financiamento"}</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.valorTotalFinanciamento)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Valor da parcela</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.valorParcela)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Data último vencimento</strong>
                  </td>
                  <td>{MaskDate.TO_BR(this.props.contract.dataUltimoVenc)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Cidade liberação crédito</strong>
                  </td>
                  <td>{this.props.contract.cidadeLiberacaoCred}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Índices</strong>
                  </td>
                  <td>{this.props.contract.indices}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Taxa da comissão %</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.taxaComissao)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Data aditivo contrato</strong>
                  </td>
                  <td>{MaskDate.TO_BR(this.props.contract.dataAditivo)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }
    return null;
  }

  renderAlienacaoFiduciariaConsorcio() {
    if (this.props.contract.tipoContrato == 2) {
      return (
        <div className="row m-t">
          <div className="col-md-6">
            <table className="table table-striped text-right">
              <tbody>
                <tr>
                  <td>
                    <strong>{this.props.contract.uf && this.props.contract.uf === "MG" ? "Valor da dívida" : "Valor total do financiamento"}</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.valorTotalFinanciamento)}</td>
                </tr>

                <tr>
                  <td>
                    <strong>Quantidade de parcelas</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.qtdeParcelas)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Data primeiro vencimento</strong>
                  </td>
                  <td>{MaskDate.TO_BR(this.props.contract.dataPrimeiroVenc)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>UF liberação do crédito</strong>
                  </td>
                  <td>{this.props.contract.ufLiberacaoCred}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Data liberação crédito</strong>
                  </td>
                  <td>{MaskDate.TO_BR(this.props.contract.dataLiberacaoCred)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Número grupo consórcio</strong>
                  </td>
                  <td>{this.props.contract.numGrupoConsorcio}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Valor do crédito</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.valorCredito)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Texto da penalidade</strong>
                  </td>
                  <td>{this.props.contract.penalidade}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Número aditivo contrato</strong>
                  </td>
                  <td>{this.props.contract.numeroAditivo}</td>
                </tr>
                <tr>
                  <td>
                    <strong>IPOC</strong>
                  </td>
                  <td>{this.props.contract.ipoc}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6">
            <table className="table table-striped text-right">
              <tbody>
                <tr>
                  <td>
                    <strong>Valor do IOF</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.valorIOF)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Valor da parcela</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.valorParcela)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Data último vencimento</strong>
                  </td>
                  <td>{MaskDate.TO_BR(this.props.contract.dataUltimoVenc)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Cidade liberação crédito</strong>
                  </td>
                  <td>{this.props.contract.cidadeLiberacaoCred}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Índices</strong>
                  </td>
                  <td>{this.props.contract.indices}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Número cota consórcio</strong>
                  </td>
                  <td>{this.props.contract.numCotaConsorcio}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Dia de vencimento das parcelas</strong>
                  </td>
                  <td>{this.props.contract.diaVencParcelas}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Taxa da comissão %</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.taxaComissao)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Data aditivo contrato</strong>
                  </td>
                  <td>{MaskDate.TO_BR(this.props.contract.dataAditivo)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }
    return null;
  }

  renderArrendamentoMercantilLeasing() {
    if (this.props.contract.tipoContrato == 4) {
      return (
        <div className="row m-t">
          <div className="col-md-6">
            <table className="table table-striped text-right">
              <tbody>
                <tr>
                  <td>
                    <strong>Taxa juros ao mês</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.taxaJurosMes)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Taxa de multa</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.taxaMulta)}</td>
                </tr>
                {this.props.contract.uf && this.props.contract.uf === "MG" && (
                  <tr>
                    <td>
                      <strong>Correção monetária</strong>
                    </td>
                    <td>{MaskDecimal.TO_BR(this.props.contract.taxaCorrecaoMonetaria)}</td>
                  </tr>
                )}
                <tr>
                  <td>
                    <strong>Valor do IOF</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.valorIOF)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Quantidade de parcelas</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.qtdeParcelas)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Data primeiro vencimento</strong>
                  </td>
                  <td>{MaskDate.TO_BR(this.props.contract.dataPrimeiroVenc)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>UF liberação do crédito</strong>
                  </td>
                  <td>{this.props.contract.ufLiberacaoCred}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Data liberação crédito</strong>
                  </td>
                  <td>{MaskDate.TO_BR(this.props.contract.dataLiberacaoCred)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Valor residual garantido</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.valorResidual)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Texto da penalidade</strong>
                  </td>
                  <td>{this.props.contract.penalidade}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Número aditivo contrato</strong>
                  </td>
                  <td>{this.props.contract.numeroAditivo}</td>
                </tr>
                <tr>
                  <td>
                    <strong>IPOC</strong>
                  </td>
                  <td>{this.props.contract.ipoc}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6">
            <table className="table table-striped text-right">
              <tbody>
                <tr>
                  <td>
                    <strong>Taxa juros ao ano</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.taxaJurosAno)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Taxa de mora</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.taxaMora)}</td>
                </tr>
                {this.props.contract.uf && this.props.contract.uf === "MG" && (
                  <tr>
                    <td>
                      <strong>
                        <br></br>
                      </strong>
                    </td>
                    <td></td>
                  </tr>
                )}
                <tr>
                  <td>
                    <strong>{this.props.contract.uf && this.props.contract.uf === "MG" ? "Valor da dívida" : "Valor total do financiamento"}</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.valorTotalFinanciamento)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Valor da parcela</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.valorParcela)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Data último vencimento</strong>
                  </td>
                  <td>{MaskDate.TO_BR(this.props.contract.dataUltimoVenc)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Cidade liberação crédito</strong>
                  </td>
                  <td>{this.props.contract.cidadeLiberacaoCred}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Índices</strong>
                  </td>
                  <td>{this.props.contract.indices}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Tipo do valor residual</strong>
                  </td>
                  <td>{this.props.contract.tipoValorResidual}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Taxa da comissão %</strong>
                  </td>
                  <td>{MaskDecimal.TO_BR(this.props.contract.taxaComissao)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Data aditivo contrato</strong>
                  </td>
                  <td>{MaskDate.TO_BR(this.props.contract.dataAditivo)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    if (!this.props.companies || !this.props.contract) {
      return null;
    }

    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Visualização de contrato" />
        <div className="content">
          {this.props.errorMessage && this.props.errorMessage && (
            <div className="row">
              <div className="col-lg-12">
                <div className="hpanel">
                  <div className="alert alert-warning">
                    <i className="fa fa-comment"></i> {this.props.errorMessage}
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.props.comunicacaoDetran && this.props.contract.status == 90 && !this.props.errorMessage && (
            <div className="row">
              <div className="col-lg-12">
                <div className="hpanel">
                  <div className="alert alert-warning">
                    <i className="fa fa-comment"></i> {this.props.comunicacaoDetran[0].mensagem}
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.props.lastLog && this.props.lastLog[0] && (this.props.lastLog[0].status === 10 || this.props.lastLog[0].status === 90) && this.props.lastLog[0].justificativa && (
            <div className="row">
              <div className="col-lg-12">
                <div className="hpanel">
                  <div className="alert alert-warning">
                    <i className="fa fa-comment"></i> {this.props.lastLog[0].justificativa}
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.props.showAverbacao && !this.props.currentUser.perfis.some((item) => item == "ALIAS") && (
            <div className="row">
              <div className="col-lg-12">
                <div className="hpanel">
                  {this.props.contract.uf !== "BA" && this.props.contract.uf !== "MA" && this.props.contract.uf !== "PI" && this.props.contract.uf !== "RS" ? (
                    <div className="alert alert-info">
                      <i className="fa fa-exclamation"></i> Possivelmente este contrato já foi registrado utilizando outro sistema de registro, caso deseje realizar uma averbação ou alteração
                      selecione a opção abaixo.
                      <br />
                      <br />
                      <Link className="btn btn-default btn-sm" onClick={this.handleCreateAdditive()}>
                        <i className="far fa-plus-square"></i> Averbar{" "}
                      </Link>
                      <Link className="btn btn-default btn-sm" onClick={this.handleSendChanges()}>
                        <i className="far fa-plus-square"></i> Alterar{" "}
                      </Link>
                    </div>
                  ) : (
                    <div className="alert alert-info">
                      <i className="fa fa-exclamation"></i> Possivelmente este contrato já foi registrado utilizando outro sistema de registro, de acordo com a legislação do estado é necessário que
                      alterações e averbações sejam realizadas pelo mesmo sistema.
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-body">
                  <div className="row">
                    {!this.props.isUploading && (
                      <div className="col-md-12">
                        {this.props.currentUser.perfis.some((item) => item == "ALIAS") && (this.props.contract.status === 3 || this.props.contract.status === 8) ? (
                          <div className="row">
                            <div className="text-left col-md-8">
                              <Link className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={browserHistory.goBack}>
                                <i className="fas fa-arrow-left"></i> Voltar{" "}
                              </Link>
                              {this.props.currentUser.funcionalidades.includes(12) && (
                                <button className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={this.handleOnPrintClick()}>
                                  Imprimir
                                </button>
                              )}
                              {this.props.currentUser.funcionalidades.includes(91) && (
                                <button className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={this.handleClickImage()} disabled={this.state.disabledBtnEspelho}>
                                  <i className="fas fa-image"></i> Imagem{" "}
                                </button>
                              )}
                              {this.props.currentUser.funcionalidades.includes(13) && (
                                <button className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={this.exportContractPDF()} disabled={this.state.disabledBtnEspelho}>
                                  {" "}
                                  <SvgIcon size={15} icon={filePdf} />
                                  Espelho
                                </button>
                              )}
                            </div>
                            {this.props.contract.status === 3 && (
                              <div className="text-right col-md-4">
                                {this.props.currentUser.funcionalidades.includes(14) && (
                                  <button className="btn btn-primary btn-sm" style={{ margin: "0 2px" }} onClick={this.handleOnCancelContractAlias()}>
                                    <i className="fas fa-window-close"></i> {this.props.contract.uf === "BA" ? " Enviar Cancelamento ao Detran" : " Cancelar"}
                                  </button>
                                )}
                                {this.props.currentUser.funcionalidades.includes(15) && (
                                  <button className="btn btn-primary btn-sm" style={{ margin: "0 2px" }} onClick={this.handleOnShelveContractAlias()}>
                                    <i className="fas fa-archive"></i> {this.props.contract.uf === "BA" ? "  Enviar Baixa ao Detran" : " Baixar"}
                                  </button>
                                )}
                              </div>
                            )}
                            {/* <div className="text-right col-md-4">
                                  <Link className="btn btn-primary btn-sm" style={{margin: '0 2px'}} onClick={this.handleClickAprove()}><i className="fas fa-check-square"></i> Homologar </Link>
                                  <Link className="btn btn-primary btn-sm" style={{margin: '0 2px'}} onClick={this.handleClickNotAprove()}><i className="fas fa-window-close"></i> Reprovar </Link>
                                </div>                                 */}
                          </div>
                        ) : this.props.currentUser.perfis.some((item) => item == "ALIAS") && this.props.contract.status !== 3 && this.props.contract.status !== 8 ? (
                          <div className="row">
                            <div className="text-left col-md-8">
                              <Link className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={browserHistory.goBack}>
                                <i className="fas fa-arrow-left"></i> Voltar{" "}
                              </Link>
                              {this.props.currentUser.funcionalidades.includes(12) && (
                                <button className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={this.handleOnPrintClick()}>
                                  Imprimir
                                </button>
                              )}
                              {this.props.currentUser.funcionalidades.includes(13) && (
                                <button className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={this.exportContractPDF()} disabled={this.state.disabledBtnEspelho}>
                                  {" "}
                                  <SvgIcon size={15} icon={filePdf} />
                                  Espelho
                                </button>
                              )}
                            </div>
                            {this.props.contract.status === 2 && (
                              <div className="text-right col-md-4">
                                {this.props.currentUser.funcionalidades.includes(14) && (
                                  <button className="btn btn-primary btn-sm" style={{ margin: "0 2px" }} onClick={this.handleOnCancelContractAlias()}>
                                    <i className="fas fa-window-close"></i> {this.props.contract.uf === "BA" ? " Enviar Cancelamento ao Detran" : " Cancelar"}
                                  </button>
                                )}
                                {this.props.currentUser.funcionalidades.includes(15) && (
                                  <button className="btn btn-primary btn-sm" style={{ margin: "0 2px" }} onClick={this.handleOnShelveContractAlias()}>
                                    <i className="fas fa-archive"></i> {this.props.contract.uf === "BA" ? "  Enviar Baixa ao Detran" : " Baixar"}
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        ) : this.props.currentUser.perfis.some((item) => item == "DETRAN") ? (
                          <div className="row">
                            <div className="text-left col-md-12">
                              <Link className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={browserHistory.goBack} to={`/sdetran`}>
                                <i className="fas fa-arrow-left"></i> Voltar{" "}
                              </Link>
                              {this.props.currentUser.funcionalidades.includes(91) && (
                                <button className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={this.handleClickImage()} disabled={this.state.disabledBtnEspelho}>
                                  <i className="fas fa-image"></i> Imagem{" "}
                                </button>
                              )}
                              {this.props.currentUser.funcionalidades.includes(12) && (
                                <button className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={this.handleOnPrintClick()}>
                                  Imprimir
                                </button>
                              )}
                            </div>
                          </div>
                        ) : this.props.contract.status === 0 || this.props.contract.status === 8 ? (
                          <div className="row">
                            <div className="text-left col-md-6">
                              <Link className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={browserHistory.goBack}>
                                <i className="fas fa-arrow-left"></i> Voltar
                              </Link>
                              {this.props.currentUser.funcionalidades.includes(26) && ( //Imprimir
                                <button className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={this.handleOnPrintClick()}>
                                  Imprimir
                                </button>
                              )}
                            </div>
                            <div className="text-right col-md-6">
                              {this.props.currentUser.funcionalidades.includes(22) && ( //Editar
                                <Link className="btn btn-default btn-sm" style={{ margin: "0 2px" }} to={`/secure/contract/${this.props.contract.id}`}>
                                  <i className="far fa-edit"></i> Editar{" "}
                                </Link>
                              )}
                            </div>
                          </div>
                        ) : this.props.contract.status === 2 ? (
                          <div className="row">
                            <div className="text-left col-md-4">
                              <Link className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={browserHistory.goBack}>
                                <i className="fas fa-arrow-left"></i> Voltar
                              </Link>
                              {this.props.currentUser.funcionalidades.includes(26) && ( //Imprimir
                                <button className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={this.handleOnPrintClick()}>
                                  Imprimir
                                </button>
                              )}
                              {this.props.currentUser.funcionalidades.includes(31) && ( //Ver Espelho
                                <button className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={this.exportContractPDF()} disabled={this.state.disabledBtnEspelho}>
                                  {" "}
                                  <SvgIcon size={15} icon={filePdf} />
                                  Espelho
                                </button>
                              )}
                            </div>
                            <div className="text-right col-md-8">
                              {this.props.currentUser.funcionalidades.includes(27) && ( //Enviar imagem
                                <Link className="btn btn-default btn-sm" style={{ margin: "0 2px" }} to={`/secure/contract/${this.props.contract.id}`}>
                                  <i className="far fa-edit"></i> Enviar Imagem{" "}
                                </Link>
                              )}
                              {this.props.currentUser.funcionalidades.includes(22) &&
                                this.props.contract.uf !== "RS" &&
                                this.props.contract.uf !== "RN" &&
                                this.props.contract.uf !== "MS" && ( //Editar
                                  <Link className="btn btn-default btn-sm" style={{ margin: "0 2px" }} to={`/secure/contract/${this.props.contract.id}`}>
                                    <i className="far fa-edit"></i> Alterar{" "}
                                  </Link>
                                )}
                              {/* {this.props.contract.uf && this.props.contract.uf === "BA" && (
                                <button className="btn btn-primary btn-sm" style={{ margin: "0 2px" }} onClick={this.handleOnCancelContract()}>
                                  <i className="fas fa-window-close"></i> {this.props.contract.uf === "BA" ? " Enviar Cancelamento ao Detran" : " Cancelar"}
                                </button>
                              )}
                              {this.props.contract.uf && this.props.contract.uf === "BA" && (
                                <button className="btn btn-primary btn-sm" style={{ margin: "0 2px" }} onClick={this.handleOnShelveContract()}>
                                  <i className="fas fa-archive"></i> {this.props.contract.uf === "BA" ? " Enviar Baixa ao Detran" : " Baixar"}
                                </button>
                              )} */}
                            </div>
                          </div>
                        ) : this.props.contract.status === 5 || this.props.contract.status === 6 || this.props.contract.status === 7 ? (
                          <div className="row">
                            <div className="text-left col-md-4">
                              <Link className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={browserHistory.goBack}>
                                <i className="fas fa-arrow-left"></i> Voltar
                              </Link>
                            </div>
                          </div>
                        ) : this.props.contract.status === 4 || this.props.contract.status === 3 || this.props.contract.status === 2 ? (
                          <div className="row">
                            <div className="text-left col-md-3">
                              <Link className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={browserHistory.goBack}>
                                <i className="fas fa-arrow-left"></i> Voltar
                              </Link>
                              {this.props.currentUser.funcionalidades.includes(26) && ( //Imprimir
                                <button className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={this.handleOnPrintClick()}>
                                  Imprimir
                                </button>
                              )}
                              {this.props.currentUser.funcionalidades.includes(30) && ( //Ver Imagem
                                <button className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={this.handleClickImage()} disabled={this.state.disabledBtnEspelho}>
                                  <SvgIcon size={15} icon={image} />
                                  Imagem{" "}
                                </button>
                              )}
                              {this.props.currentUser.funcionalidades.includes(31) && ( //Ver Espelho
                                <button className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={this.exportContractPDF()} disabled={this.state.disabledBtnEspelho}>
                                  {" "}
                                  <SvgIcon size={15} icon={filePdf} />
                                  Espelho
                                </button>
                              )}
                            </div>
                            <div className="text-right col-md-9">
                              {this.props.contract.uf !== "BA" && this.props.contract.uf !== "MA" && this.props.contract.uf !== "PI" && this.props.contract.uf !== "RS" && (
                                <Link className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={this.handleCreateAdditive()}>
                                  <i className="far fa-plus-square"></i> Averbar{" "}
                                </Link>
                              )}
                              {this.props.currentUser.funcionalidades.includes(22) &&
                                this.props.contract.uf !== "RS" &&
                                this.props.contract.uf !== "RN" &&
                                this.props.contract.uf !== "MS" && ( //Editar
                                  <Link className="btn btn-default btn-sm" style={{ margin: "0 2px" }} to={`/secure/contract/${this.props.contract.id}`}>
                                    <i className="far fa-edit"></i> Alterar{" "}
                                  </Link>
                                )}
                              {/* {this.props.contract.uf && this.props.contract.uf === "BA" && (
                                <button className="btn btn-primary btn-sm" style={{ margin: "0 2px" }} onClick={this.handleOnCancelContract()}>
                                  <i className="fas fa-window-close"></i>
                                  {this.props.contract.uf === "BA" ? " Enviar Cancelamento ao Detran" : " Cancelar"}
                                </button>
                              )}
                              {this.props.contract.uf && this.props.contract.uf === "BA" && (
                                <button className="btn btn-primary btn-sm" style={{ margin: "0 2px" }} onClick={this.handleOnShelveContract()}>
                                  <i className="fas fa-archive"></i>
                                  {this.props.contract.uf === "BA" ? "  Enviar Baixa ao Detran" : " Baixar"}
                                </button>
                              )} */}
                              {this.props.currentUser.funcionalidades.includes(32) && ( //Alterar imagem
                                <Dropzone accept="application/pdf" name="file" className="dropzone-blank fallback float-right" onDrop={(filesToUpload, e) => this.handleClickUpload(filesToUpload)}>
                                  <button type="button" className="btn btn-primary btn-sm">
                                    <i className="fa fa-upload" /> Alterar Imagem
                                  </button>
                                </Dropzone>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="text-right">{/*this.props.contract.status*/}</div>
                        )}
                      </div>
                    )}
                    <div className="col-md-12">
                      {this.props.contract.status === 0 ? (
                        <h4>Não Registrado</h4>
                      ) : (
                        <h4>
                          Número do Registro no Detran: <small>{this.props.contract.numRegistro}</small>
                        </h4>
                      )}
                      {this.props.contract
                        ? this.props.contract.status === 0
                          ? "Em Criação"
                          : this.props.contract.status === 1 || this.props.contract.status === 9
                          ? "Aguardando DETRAN"
                          : this.props.contract.status === 2
                          ? "Registrado - Sem Imagem"
                          : this.props.contract.status === 3
                          ? "Registrado"
                          : this.props.contract.status === 4
                          ? "Homologado"
                          : this.props.contract.status === 5
                          ? "Baixado"
                          : this.props.contract.status === 6
                          ? "Cancelado"
                          : this.props.contract.status === 10
                          ? "Não Homologado"
                          : this.props.contract.status === 11
                          ? "Em Processamento"
                          : this.props.contract.status === 7
                          ? "Averbado"
                          : this.props.contract.status === 8
                          ? "CRIADO"
                          : ""
                        : ""}
                    </div>
                  </div>
                </div>
                <div id="toPrint" className="panel-body p-xl">
                  <div className="row m-b-xl">
                    <div className="col-sm-6">
                      <h4>
                        <small>CONTRATO:</small> {this.props.contract.numero}
                      </h4>
                      <p>
                        <span>
                          <strong>UF:</strong> {this.props.contract.uf}
                        </span>
                        <span>
                          <strong> Data:</strong> {MaskDate.TO_BR(this.props.contract.data)}
                        </span>
                        <br />
                      </p>
                    </div>
                  </div>

                  <div className="row m-b-xl">
                    <div className="col-sm-6">
                      <h4>CREDOR</h4>
                      <span>
                        <strong>CNPJ:</strong> {this.props.company ? MaskCNPJ.TO_CNPJ(this.props.company.cnpj) : ""}
                      </span>
                      <br />
                      <span>
                        <strong>NOME:</strong> {this.props.company ? this.props.company.razaoSocial : ""}
                      </span>
                      <br />
                      <span>
                        <strong>MUNICÍPIO:</strong> {this.props.company ? this.props.company.endMunicipioNome : ""}
                      </span>{" "}
                      <span>
                        <strong>UF:</strong> {this.props.company ? this.props.company.endUf : ""}
                      </span>{" "}
                      <span>
                        <strong>CEP:</strong> {this.props.company ? this.props.company.endCep : ""}
                      </span>
                      <br />
                      <span>
                        <strong>ENDEREÇO:</strong> {this.props.company ? this.props.company.endereco : ""}
                      </span>{" "}
                      <span>
                        <strong>NÚMERO:</strong> {this.props.company ? this.props.company.endNumero : ""}
                      </span>
                      <br />
                      <span>
                        <strong>COMPLEMENTO:</strong> {this.props.company ? this.props.company.endComplemento : ""}
                      </span>{" "}
                      <span>
                        <strong>BAIRRO:</strong> {this.props.company ? this.props.company.endBairro : ""}
                      </span>
                      <br />
                      <span>
                        <strong>TELEFONE:</strong> {this.props.company ? `(${this.props.company.dddTelefone}) ${this.props.company.numTelefone}` : ""}{" "}
                      </span>{" "}
                      <br />
                    </div>
                    <div className="col-sm-6 text-right">
                      <h4>DEVEDOR</h4>
                      <span>
                        <strong>CPF/CNPJ:</strong> {this.props.contract.devCpfCnpj}
                      </span>
                      <br />
                      <span>
                        <strong>NOME:</strong> {this.props.contract.devNome}
                      </span>
                      <br />
                      <span>
                        <strong>MUNICÍPIO:</strong> {this.props.contract.municipio}
                      </span>{" "}
                      <span>
                        <strong>UF:</strong> {this.props.contract.devUf}
                      </span>{" "}
                      <span>
                        <strong>CEP:</strong> {this.props.contract.devCep}
                      </span>
                      <br />
                      <span>
                        <strong>ENDEREÇO:</strong> {this.props.contract.devEndereco}
                      </span>{" "}
                      <span>
                        <strong>NÚMERO:</strong> {this.props.contract.devNumero}
                      </span>
                      <br />
                      <span>
                        <strong>COMPLEMENTO:</strong> {this.props.contract.devComplemento}
                      </span>{" "}
                      <span>
                        <strong>BAIRRO:</strong> {this.props.contract.devBairro}
                      </span>
                      <br />
                      <span>
                        <strong>TELEFONE:</strong> ({this.props.contract.devDddTelefone}) {this.props.contract.devTelefone}
                      </span>{" "}
                      <span>
                        <strong>CELULAR:</strong> ({this.props.contract.devDddCelular}) {this.props.contract.devCelular}
                      </span>
                      <br />
                      <span>
                        <strong>EMAIL:</strong> {this.props.contract.devEmail}
                      </span>
                      <br />
                    </div>
                  </div>

                  <h4>VEÍCULOS</h4>
                  <div className="table-responsive m-t">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Gravame</th>
                          <th>Renavam</th>
                          <th>Chassi</th>
                          <th>Remarcado</th>
                          <th>UF</th>
                          <th>Placa</th>
                          <th>Licenciamento</th>
                          <th>Fabricação</th>
                          <th>Modelo</th>
                          {this.props.contract.uf && this.props.contract.uf === "RJ" && <th>Marca/Modelo</th>}
                          <th>Data do Gravame</th>
                          <th>Espécie/Tipo</th>
                        </tr>
                      </thead>
                      <tbody>{this.renderVehicleList()}</tbody>
                    </table>
                  </div>

                  <br />
                  <h4>COMUNICAÇÃO DETRAN</h4>
                  <div className="table-responsive m-t">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Data de inclusao</th>
                          <th>Operação</th>
                          <th>Data de processamento</th>
                          <th>Mensagem</th>
                        </tr>
                      </thead>
                      <tbody>{this.renderComunicaoDetran()}</tbody>
                    </table>
                  </div>

                  <br />
                  <h4>DADOS CONTRATUAIS</h4>
                  <p>
                    <span>
                      <strong>Tipo do Contrato:</strong>{" "}
                      {this.props.contract.tipoContrato
                        ? this.props.contract.tipoContrato === 1
                          ? "Alienação Fiduciária - Financiamento"
                          : this.props.contract.tipoContrato === 2
                          ? "Alienação Fiduciária - Consórcio"
                          : this.props.contract.tipoContrato === 3
                          ? "Alienação Fiduciária - Cédula de Crédito"
                          : this.props.contract.tipoContrato === 4
                          ? "Arrendamento Mercantil - Leasing"
                          : this.props.contract.tipoContrato === 5
                          ? "Reserva de Domínio"
                          : this.props.contract.tipoContrato === 6
                          ? "Penhor"
                          : ""
                        : ""}
                    </span>
                    <br />
                  </p>
                  {this.renderAlienacaoFiduciariaFinanciamentoOuReservaDeDominioOuPenhor()}
                  {this.renderAlienacaoFiduciariaConsorcio()}
                  {this.renderArrendamentoMercantilLeasing()}
                </div>
                <div className="hpanel">
                  <div className="v-timeline vertical-container animate-panel" data-child="vertical-timeline-block" data-delay="1">
                    <ContractLog logs={this.props.log} position="top center" user={this.props.currentUser} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  exportContractPDF = () => async (ev) => {
    if (!this.props.log || this.props.log.length == 0) {
      this.setState({ disabledBtnEspelho: true });
      toastr.info("Aguarde", "Processando arquivo...", {
        timeOut: 0,
        component: () => {
          return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
        },
      });
    }
    while (!this.props.log || this.props.log.length == 0) {
      await delay(2000);
    }
    this.setState({ disabledBtnEspelho: false });
    toastr.removeByType("info");
    ev.preventDefault();
    var doc = new jsPDF();
    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();

    const marginLeft = 20;
    const marginRight = 100;
    const fontSizeTitle = 16;
    const fontSizeSubTitle = 12;
    const fontSize = 10;

    var altura = 4.5;
    var atualY = 8;

    var image64;
    if (this.props.configLayout && this.props.configLayout.logo_sistema) {
      image64 = `data:image/png;base64,${this.props.configLayout.logo_sistema}`;
    } else {
      image64 = logoEspelhoBase64;
    }

    doc.line(10, 36, 200, 36);
    doc.line(10, 36, 10, 47); // vertical line
    doc.line(10, 47, 200, 47);
    doc.line(200, 47, 200, 36); // vertical line

    doc.addImage(image64, "PNG", width / 2 - 16, 6, 31, 15);
    doc.setFontSize(fontSizeTitle);
    doc.setFontStyle("bold");
    doc.text("Espelho Eletrônico do Contrato", 105, 33, 0, null, "center");

    doc.setFontSize(fontSize);
    addText(`Número do Registro no Detran: ${this.props.contract.numRegistro}`, 12);
    addText(`Número Contrato Físico: ${this.props.contract.numero}`, 12);

    doc.setFontSize(fontSizeSubTitle);
    ++atualY;
    addText("Dados do Contrato", 10);
    doc.setFontSize(fontSize);
    doc.setFontStyle("normal");
    addText(
      `Tipo de Contrato: ${
        this.props.contract.tipoContrato
          ? this.props.contract.tipoContrato === 1
            ? "Alienação Fiduciária - Financiamento"
            : this.props.contract.tipoContrato === 2
            ? "Alienação Fiduciária - Consórcio"
            : this.props.contract.tipoContrato === 3
            ? "Alienação Fiduciária - Cédula de Crédito"
            : this.props.contract.tipoContrato === 4
            ? "Arrendamento Mercantil - Leasing"
            : this.props.contract.tipoContrato === 5
            ? "Reserva de Domínio"
            : this.props.contract.tipoContrato === 6
            ? "Penhor"
            : ""
          : ""
      }`,
      marginLeft
    );
    if (this.props.contract.valorTotalFinanciamento)
      addText(
        `${this.props.contract.uf && this.props.contract.uf === "MG" ? "Valor da dívida" : "Valor total do financiamento"}: ${MaskDecimal.TO_BRL(this.props.contract.valorTotalFinanciamento)}`,
        marginLeft
      );
    if (this.props.contract.valorIOF) addText(`Valor do IOF: ${MaskDecimal.TO_BRL(this.props.contract.valorIOF)}`, marginLeft);
    if (this.props.contract.qtdeParcelas) addText(`Quantidade de parcelas: ${MaskDecimal.TO_BR(this.props.contract.qtdeParcelas)}`, marginLeft);
    if (this.props.contract.valorParcela) addText(`Valor da parcela: ${MaskDecimal.TO_BRL(this.props.contract.valorParcela)}`, marginLeft);
    addTextTwoParams(
      `Data primeiro vencimento: ${this.props.contract.dataPrimeiroVenc ? MaskDate.TO_BR(this.props.contract.dataPrimeiroVenc) : ""}`,
      marginLeft,
      `Data último vencimento: ${this.props.contract.dataUltimoVenc ? MaskDate.TO_BR(this.props.contract.dataUltimoVenc) : ""}`,
      marginRight
    );
    addTextTwoParams(
      `UF liberação do crédito: ${this.props.contract.ufLiberacaoCred ? this.props.contract.ufLiberacaoCred : ""}`,
      marginLeft,
      `Cidade liberação crédito: ${this.props.contract.cidadeLiberacaoCred ? this.props.contract.cidadeLiberacaoCred : ""}`,
      marginRight
    );
    addTextTwoParams(
      `Data liberação crédito: ${this.props.contract.dataLiberacaoCred ? MaskDate.TO_BR(this.props.contract.dataLiberacaoCred) : ""}`,
      marginLeft,
      `Índices: ${this.props.contract.indices ? this.props.contract.indices : ""}`,
      marginRight
    );
    if (this.props.contract.numGrupoConsorcio) addText(`Número grupo consórcio: ${this.props.contract.numGrupoConsorcio}`, marginLeft);
    if (this.props.contract.numCotaConsorcio) addText(`Número cota consórcio: ${this.props.contract.numCotaConsorcio}`, marginLeft);
    if (this.props.contract.valorCredito) addText(`Valor do crédito: ${MaskDecimal.TO_BRL(this.props.contract.valorCredito)}`, marginLeft);
    if (this.props.contract.diaVencParcelas) addText(`Dia de vencimento das parcelas: ${this.props.contract.diaVencParcelas}`, marginLeft);
    if (this.props.contract.taxaComissao) addText(`Taxa da comissão %: ${MaskDecimal.TO_BR(this.props.contract.taxaComissao)}`, marginLeft);
    if (this.props.contract.taxaJurosMes) addText(`Taxa juros ao mês: ${MaskDecimal.TO_BR(this.props.contract.taxaJurosMes)}`, marginLeft);
    if (this.props.contract.taxaJurosAno) addText(`Taxa juros ao ano: ${MaskDecimal.TO_BR(this.props.contract.taxaJurosAno)}`, marginLeft);
    if (this.props.contract.taxaMulta) addText(`Taxa de multa: ${MaskDecimal.TO_BR(this.props.contract.taxaMulta)}`, marginLeft);
    if (this.props.contract.taxaMora) addText(`Taxa de mora: ${MaskDecimal.TO_BR(this.props.contract.taxaMora)}`, marginLeft);
    if (this.props.contract.taxaCorrecaoMonetaria) addText(`Correção monetária: ${MaskDecimal.TO_BR(this.props.contract.taxaCorrecaoMonetaria)}`, marginLeft);
    if (this.props.contract.valorResidual) addText(`Valor residual garantido: ${MaskDecimal.TO_BRL(this.props.contract.valorResidual)}`, marginLeft);
    if (this.props.contract.tipoValorResidual) addText(`Tipo do valor residual: ${MaskDecimal.TO_BR(this.props.contract.tipoValorResidual)}`, marginLeft);
    if (this.props.contract.numeroAditivo) addText(`Número aditivo contrato: ${this.props.contract.numeroAditivo}`, marginLeft);
    if (this.props.contract.dataAditivo) addText(`Data aditivo contrato: ${this.props.contract.dataAditivo}`, marginLeft);
    if (this.props.contract.penalidade) addText(`Texto da penalidade: ${this.props.contract.penalidade}`, marginLeft);
    if (this.props.contract.ipoc) addText(`IPOC: ${this.props.contract.ipoc}`, marginLeft);

    doc.setFontSize(fontSizeSubTitle);
    ++atualY;
    doc.line(10, atualY * altura, width - 10, atualY * altura);
    doc.setFontStyle("bold");
    addText("Credor", 10);
    doc.setFontSize(fontSize);
    doc.setFontStyle("normal");
    if (this.props.company) {
      addText(`Nome do Credor: ${this.props.company.razaoSocial}`, marginLeft);
      addText(`CNPJ do Credor: ${MaskCNPJ.TO_CNPJ(this.props.company.cnpj)}`, marginLeft);
    }

    doc.setFontSize(fontSizeSubTitle);
    ++atualY;
    doc.line(10, atualY * altura, width - 10, atualY * altura);
    doc.setFontStyle("bold");
    addText("Dados do Devedor", 10);
    doc.setFontSize(fontSize);
    doc.setFontStyle("normal");
    if (this.props.contract.devNome) addText(`Nome do Devedor: ${this.props.contract.devNome}`, marginLeft);
    if (this.props.contract.devCpfCnpj) addText(`CPF/CNPJ do Devedor: ${this.props.contract.devCpfCnpj}`, marginLeft);
    addTextTwoParams(
      `Telefone: ${this.props.contract.devTelefone ? `(${this.props.contract.devDddTelefone})` + ` ` + this.props.contract.devTelefone : ""}`,
      marginLeft,
      `Celular: ${this.props.contract.devCelular ? `(${this.props.contract.devDddCelular})` + ` ` + this.props.contract.devCelular : ""}`,
      marginRight
    );
    if (this.props.contract.devCep) addText(`Cep: ${this.props.contract.devCep}`, marginLeft);
    addTextTwoParams(
      `Estado: ${this.props.contract.devUf ? this.props.contract.devUf : ""}`,
      marginLeft,
      `Município: ${this.props.contract.municipio ? this.props.contract.municipio : ""}`,
      marginRight
    );
    if (this.props.contract.devEndereco) addText(`Endereço: ${this.props.contract.devEndereco}`, marginLeft);
    if (this.props.contract.devComplemento) addText(`Complemento: ${this.props.contract.devComplemento}`, marginLeft);
    addTextTwoParams(
      `Número: ${this.props.contract.devNumero ? this.props.contract.devNumero : ""}`,
      marginLeft,
      `Bairro: ${this.props.contract.devBairro ? this.props.contract.devBairro : ""}`,
      marginRight
    );

    doc.setFontSize(fontSizeSubTitle);
    ++atualY;
    doc.line(10, atualY * altura, width - 10, atualY * altura);
    doc.setFontStyle("bold");
    addText("Veículos", 10);
    doc.setFontSize(fontSize);
    doc.setFontStyle("normal");
    if (!this.props.vehicles || this.props.vehicles.length === 0) {
      addText("Nenhum veículo informado", marginLeft);
    } else {
      for (var i = 0; i < this.props.vehicles.length; ++i) {
        addTextTwoParams(
          `Gravame: ${this.props.vehicles[i].numGravame ? this.props.vehicles[i].numGravame : ""}`,
          marginLeft,
          `Renavam: ${this.props.vehicles[i].renavam ? this.props.vehicles[i].renavam : ""}`,
          marginRight
        );
        addTextTwoParams(
          `Chassi: ${this.props.vehicles[i].chassi ? this.props.vehicles[i].chassi : ""}`,
          marginLeft,
          `Remarcado: ${this.props.vehicles[i].chassiRemarcado ? (this.props.vehicles[i].chassiRemarcado == 1 ? "Sim" : "Não") : ""}`,
          marginRight
        );
        addTextTwoParams(
          `UF: ${this.props.vehicles[i].ufPlaca ? this.props.vehicles[i].ufPlaca : ""}`,
          marginLeft,
          `Placa: ${this.props.vehicles[i].numPlaca ? this.props.vehicles[i].numPlaca : ""}`,
          marginRight
        );
        addTextTwoParams(
          `Licenciamento: ${this.props.vehicles[i].ufLicenciamento ? this.props.vehicles[i].ufLicenciamento : ""}`,
          marginLeft,
          `Ano Fabricação: ${this.props.vehicles[i].anoFabricacao ? this.props.vehicles[i].anoFabricacao : ""}`,
          marginRight
        );
        addTextTwoParams(
          `Ano Modelo: ${this.props.vehicles[i].anoModelo ? this.props.vehicles[i].anoModelo : ""}`,
          marginLeft,
          `Data do Gravame: ${this.props.vehicles[i].dataGravame ? MaskDate.TO_BR(this.props.vehicles[i].dataGravame) : ""}`,
          marginRight
        );
        addTextTwoParams(
          `Espécie/Tipo: ${
            this.props.vehicles[i].especieVeiculo
              ? this.props.vehicles[i].especieVeiculo == 1
                ? "Motocicleta"
                : this.props.vehicles[i].especieVeiculo == 2
                ? "Automóvel"
                : this.props.vehicles[i].especieVeiculo == 3
                ? "Micro-ônibus"
                : this.props.vehicles[i].especieVeiculo == 4
                ? "Ônibus"
                : this.props.vehicles[i].especieVeiculo == 5
                ? "Caminhão"
                : this.props.vehicles[i].especieVeiculo == 6
                ? "Reboque/Semi-Reboque"
                : this.props.vehicles[i].especieVeiculo == 7
                ? "Caminhonete(a)"
                : this.props.vehicles[i].especieVeiculo == 8
                ? "Outros"
                : ""
              : ""
          }`,
          marginLeft,
          "",
          marginRight
        );
        ++atualY;
      }
    }

    doc.setFontSize(fontSizeSubTitle);
    doc.line(10, atualY * altura, width - 10, atualY * altura);
    doc.setFontStyle("bold");
    addText("Situação do Contrato", 10);
    doc.setFontSize(fontSize);
    doc.setFontStyle("normal");
    var dataReg = "";
    if (this.props.contract.status === 2 || this.props.contract.status === 3 || this.props.contract.status === 4) {
      var valor = false;
      this.props.log.map((log, index) => {
        if (!isEmpty(log.statusContrato) && log.statusContrato === 2 && valor === false) {
          dataReg = MaskDate.TO_BR_DATETIME(log.data);
          addText(`Contrato Registrado no Detran por: ${log.nome} em ${MaskDate.TO_BR_DATETIME(log.data)}.`, marginLeft);
          addText(`IP: ${log.ip}`, marginLeft);
          valor = true;
        }
      });
    } else {
      addText(`Contrato não Registrado no Detran.`, marginLeft);
    }

    doc.setFontSize(fontSizeSubTitle);
    ++atualY;
    doc.line(10, atualY * altura, width - 10, atualY * altura);
    doc.setFontStyle("bold");
    addText("Situação da Imagem do Contrato", 10);
    doc.setFontSize(fontSize);
    doc.setFontStyle("normal");
    if (this.props.contract.status === 2 || this.props.contract.status === 3 || this.props.contract.status === 4) {
      var valor = false;
      for (const log of this.props.log) {
        if (log.statusContrato && log.statusContrato === 3 && valor === false) {
          addText(`Imagem do Contrato enviada por: ${log.nome} em ${MaskDate.TO_BR_DATETIME(log.data)}.`, marginLeft);
          addText(`IP: ${log.ip}`, marginLeft);
          valor = true;
          break;
        }
      }
      if (valor === false) {
        addText(`Imagem do Contrato não Registrada no Detran.`, marginLeft);
      }
    } else {
      addText(`Imagem do Contrato não Registrada no Detran.`, marginLeft);
    }

    doc.setFontSize(fontSizeSubTitle);
    ++atualY;
    doc.line(10, atualY * altura, width - 10, atualY * altura);
    doc.setFontStyle("bold");
    addText("Autenticação", 10);
    doc.setFontSize(7);
    doc.setFontStyle("normal");
    //var autenticacao = Base64.stringify(Utf8.parse(AES.encrypt(`${this.props.contract.numRegistro} ${dataReg}`, "alias_secret")));
    let autenticacao = OtherUtils.encrypt(Buffer.from(`${this.props.contract.numRegistro} ${dataReg}`), "alias_secret").toString("base64");
    addText(autenticacao, marginLeft);

    doc.setFontSize(fontSizeSubTitle);
    ++atualY;
    doc.line(10, atualY * altura, width - 10, atualY * altura);
    doc.setFontStyle("bold");
    addText("Comunicação Detran", 10);
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    this.props.comunicacaoDetran.map((log, index) => {
      addText(`Data de inclusão: ${MaskDate.TO_BR_DATETIME(log.dataInclusao)}`, marginLeft);
      addText(`Data de processamento: ${MaskDate.TO_BR_DATETIME(log.dataProcessamento)}`, marginLeft);
      addText(`Mensagem: ${log.mensagem}`, marginLeft);
      addText(` `, marginLeft);
    });

    doc.setFontSize(fontSizeSubTitle);
    ++atualY;
    if (atualY * altura > 200) {
      doc.addPage();
      doc.addImage(image64, "JPEG", width / 2 - 18, 6, 30, 17);
      atualY = 7;
    }
    doc.line(10, atualY * altura, width - 10, atualY * altura);
    doc.setFontStyle("bold");
    addText("Histórico", 10);
    doc.setFontSize(fontSize);
    doc.setFontStyle("normal");
    var contratoCriado = undefined;
    var veiculoCriado = undefined;
    var idsContrato = [];
    var idsVeiculo = [];
    this.props.log.map((log, index) => {
      if (
        !(
          log.statusVeiculo === 1 ||
          log.statusVeiculo === 3 ||
          log.statusVeiculo === 4 ||
          log.statusVeiculo === 9 ||
          log.statusVeiculo === 10 ||
          log.statusContrato === 1 ||
          log.statusContrato === 9 ||
          log.statusContrato === 90
        )
      ) {
        addText(`Usuário: ${log.nome}`, marginLeft);
        addText(`Data: ${MaskDate.TO_BR_DATETIME(log.data)}`, marginLeft);
        addText(`IP: ${log.ip}`, marginLeft);

        var contrato = false;
        var novoContrato = false;
        var averbacao = false;

        if (log.statusContrato && log.statusContrato === 8) {
          averbacao = true;
        }

        if (log.idContrato) {
          contrato = true;
          if (idsContrato.indexOf(log.idContrato) > -1) {
            novoContrato = false;
          } else {
            novoContrato = true;
          }
          idsContrato = [...idsContrato, log.idContrato];
        }
        var veiculo = false;
        var novoVeiculo = false;
        if (log.idVeiculo) {
          veiculo = true;
          if (idsVeiculo.indexOf(log.idVeiculo) > -1) {
            novoVeiculo = false;
          } else {
            novoVeiculo = true;
          }
          idsVeiculo = [...idsVeiculo, log.idVeiculo];
        }

        if (contrato) {
          addText(
            `Situação: ${
              novoContrato === true
                ? averbacao
                  ? "Averbação de Contrato Criada"
                  : "Contrato Criado"
                : log.statusContrato === 0 || log.statusContrato === 8
                ? "Contrato Editado"
                : log.statusContrato === 2 && !log.detran
                ? "Contrato Registrado"
                : log.statusContrato === 2 && log.detran && log.detran === "2"
                ? "Alteração de Contrato Enviada"
                : log.statusContrato === 3 && !log.detran
                ? "Imagem do Contrato Enviada"
                : log.statusContrato === 3 && log.detran && log.detran === "2"
                ? "Alteração de Contrato Enviada"
                : log.statusContrato === 4
                ? "Contrato Homologado"
                : log.statusContrato === 5
                ? "Contrato Baixado"
                : log.statusContrato === 6
                ? "Contrato Cancelado"
                : log.statusContrato === 7
                ? "Contrato Averbado"
                : log.statusContrato === 10
                ? "Contrato não homologado"
                : log.statusContrato
            }`,
            marginLeft
          );
        }
        if (veiculo) {
          addText(
            `Situação: ${
              novoVeiculo
                ? "Veículo Adicionado ao Contrato e Registrado"
                : log.statusVeiculo == 0
                ? "Veículo Alterado"
                : log.statusVeiculo == 91
                ? "Veículo Excluído"
                : log.statusVeiculo == 2
                ? "Veículo Registrado"
                : log.statusVeiculo == 5
                ? "Veículo Baixado"
                : log.statusVeiculo == 6
                ? "Veículo Cancelado"
                : log.statusVeiculo == 7
                ? "Veículo Averbado"
                : log.statusVeiculo == 8
                ? "Veículo Alterado"
                : log.statusVeiculo == 90
                ? "Erro no registro do veículo"
                : log.statusVeiculo
            }`,
            marginLeft
          );
        }
        ++atualY;
      }
    });

    function isEmpty(val) {
      return val === undefined || val == null || val.length <= 0 ? true : false;
    }

    function addText(text, marginLeft) {
      if ((atualY + 1) * altura > height - 20) {
        doc.addPage();
        doc.addImage(image64, "JPEG", width / 2 - 18, 6, 30, 17);
        doc.setFontSize(10);
        atualY = 7;
      }
      doc.text(text, marginLeft, ++atualY * altura);
    }

    function addTextTwoParams(textOne, marginLeft, textTwo, marginRight) {
      if ((atualY + 1) * altura > height - 20) {
        doc.addPage();
        doc.addImage(image64, "JPEG", width / 2 - 18, 6, 30, 17);
        doc.setFontSize(10);
        atualY = 7;
      }
      doc.text(textOne, marginLeft, ++atualY * altura);
      doc.text(textTwo, marginRight, atualY * altura);
    }

    const pageCount = doc.internal.getNumberOfPages();
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(String(i) + "/" + String(pageCount), width - 20, height - 10, null, null, "right");
    }

    doc.save(`Espelho${this.props.contract.numRegistro}.pdf`);
  };

  renderVehicleList() {
    if (!this.props.vehicles || this.props.vehicles.length === 0) {
      return (
        <tr>
          <td className="align-middle text-center" colSpan="4">
            <Icon icon={drawer2} /> Nenhum registro
          </td>
        </tr>
      );
    }
    return this.props.vehicles.map((v, index) => {
      return (
        <tr key={index}>
          <td>{v.numGravame}</td>
          <td>{v.renavam}</td>
          <td>{v.chassi}</td>
          <td>{v.chassiRemarcado ? (v.chassiRemarcado == 1 ? "Remarcado" : "Normal") : ""}</td>
          <td>{v.ufPlaca}</td>
          <td>{v.numPlaca}</td>
          <td>{v.ufLicenciamento}</td>
          <td>{v.anoFabricacao}</td>
          <td>{v.anoModelo}</td>
          {this.props.contract.uf && this.props.contract.uf === "RJ" && <td>{v.marcaModelo}</td>}
          <td>{MaskDate.TO_BR(v.dataGravame)}</td>
          <td>
            {v.especieVeiculo
              ? v.especieVeiculo == 1
                ? "Motocicleta"
                : v.especieVeiculo == 2
                ? "Automóvel"
                : v.especieVeiculo == 3
                ? "Micro-ônibus"
                : v.especieVeiculo == 4
                ? "Ônibus"
                : v.especieVeiculo == 5
                ? "Caminhão"
                : v.especieVeiculo == 6
                ? "Reboque/Semi-Reboque"
                : v.especieVeiculo == 7
                ? "Caminhonete(a)"
                : v.especieVeiculo == 8
                ? "Outros"
                : ""
              : ""}
          </td>
        </tr>
      );
    });
  }

  renderComunicaoDetran() {
    if (!this.props.comunicacaoDetran || this.props.comunicacaoDetran.length === 0) {
      return (
        <tr>
          <td className="align-middle text-center" colSpan="4">
            <Icon icon={drawer2} /> Nenhum registro
          </td>
        </tr>
      );
    }
    if (this.props.comunicacaoDetran) {
      return this.props.comunicacaoDetran.map((row, index) => {
        return (
          <tr key={index}>
            <td>{MaskDate.TO_BR_DATETIME(row.dataInclusao)}</td>
            <td>{row.operacao}</td>
            <td>{MaskDate.TO_BR_DATETIME(row.dataProcessamento)}</td>
            <td>{row.mensagem}</td>
          </tr>
        );
      });
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: CONTRACT_EDIT_LOAD, payload }),
  onCompaniesLoad: (payload) => dispatch({ type: CONTRACT_EDIT_COMPANIES_LOAD, payload }),
  onContractLoad: (payload) => dispatch({ type: CONTRACT_EDIT_CONTRACT_LOAD, payload }),
  onContractCancelSuccess: (payload) => dispatch({ type: CONTRACT_CANCEL_SUCCESS, payload }),
  onContractShelveSuccess: (payload) => dispatch({ type: CONTRACT_SHELVE_SUCCESS, payload }),
  onUnload: (payload) => dispatch({ type: CONTRACT_EDIT_UNLOAD, payload }),
  onOpen: (payload) => dispatch({ type: CONFIRM_OPERATION_OPEN, payload }),
  onActionOpen: (payload) => dispatch({ type: CONFIRM_ACTION_OPEN, payload }),
  onLogLoad: (payload) => dispatch({ type: CONTRACT_EDIT_LOG_LOAD, payload }),
  onLastLogLoad: (payload) => dispatch({ type: CONTRACT_EDIT_LAST_LOG_LOAD, payload }),
  onVehiclesLoad: (payload) => dispatch({ type: CONTRACT_EDIT_VEHICLE_LOAD, payload }),
  onVehicleIndexSelected: (payload) => dispatch({ type: CONTRACT_EDIT_VEHICLE_SELECTED, payload }),
  onCompanyError: (payload) => dispatch({ type: CONTRACT_EDIT_COMPANY_ERROR, payload }),
  onCompanyLoad: (payload) => dispatch({ type: CONTRACT_VIEW_COMPANY_LOAD, payload }),
  onOpenNotHomologate: (payload) => dispatch({ type: CONFIRM_NOT_HOMOLOGATE_OPEN, payload }),
  onAdditiveCreated: (payload) => dispatch({ type: CONTRACT_ADDITIVE_CREATED, payload }),
  onHomologated: (payload) => dispatch({ type: CONTRACT_HOMOLOGATED }),
  onNotHomologated: (payload) => dispatch({ type: CONTRACT_NOT_HOMOLOGATED }),
  onErrLoad: (payload) => dispatch({ type: CONTRACT_EDIT_ERR_MSG_LOAD, payload }),
  onShowAverbacaoError: (payload) => dispatch({ type: SHOW_AVERBACAO }),
  onUploading: (payload) => dispatch({ type: CONTRACT_EDIT_UPLOAD, payload }),
  onComunicaDetranLoad: (payload) => dispatch({ type: CONTRACT_COMUNICA_DETRAN, payload }),
  onLogout: () => dispatch({ type: LOGOUT }),
  onCancel: (payload) => dispatch({ type: CONTRACT_CANCEL_ALIAS }),
  onBaixa: (payload) => dispatch({ type: CONTRACT_BAIXA_ALIAS }),
});

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
  companies: state.common.companies,
  configLayout: state.common.configLayout,
  ...state.contract,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractView);
