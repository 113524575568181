import React from "react";
import { connect } from "react-redux";
import { Link, browserHistory } from "react-router";
import ReactTable from "react-table";
import fileDownload from "js-file-download";
import MaskDecimal from "../../components/Util/MaskDecimal";
import MaskDate from "../../components/Util/MaskDate";
import { toastr } from "react-redux-toastr";

import Title from "../../components/Layout/Title";
import { INVOICE_DETAIL_INVOICES_LOAD, INVOICE_DETAIL_CHARGING_LOAD, INVOICE_ANEXOS_LIST_LOAD, LOGOUT, CONFIRM_ACTION_OPEN, INVOICE_DETAIL_INVOICES_DIVERGENCY } from "../../actions/Constants";
import api from "../../api/apiUtil";

import Dropzone from "react-dropzone";
import _ from "lodash";
import { BeatLoader } from "react-spinners";
import { fileExcelO } from "react-icons-kit/fa/fileExcelO";
import SvgIcon from "react-icons-kit";
import XLSX from "xlsx/dist/xlsx.mini.min";
import * as FileSaver from "file-saver";
import MaskCPF from "../../components/Util/MaskCPF";

const columns = [
  {
    Header: "Razão Social",
    accessor: "razaoSocial",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    show: "delegaPara" === null || undefined ? true : false,
  },
  {
    Header: "Nº Registro",
    accessor: "numRegistro",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Contrato",
    accessor: "numero",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Veículo",
    accessor: "chassi",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    /*Cell: row => (
    <div>
      <small>Placa: {row.original.ufPlaca} - {row.original.numPlaca}</small><br/>
      <small>Renavam: {row.original.renavam}</small><br/>
      <small>Chassi: {row.original.chassi}</small><br/>
    </div>
      )*/
  },
  {
    Header: "Operação",
    accessor: "tipo",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Data Registro", // Custom header components!
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    accessor: "data",
    Cell: (row) => <div>{MaskDate.TO_BR(row.original.dataProcessamento)}</div>,
  },
];

const columnsDivergency = [
  {
    Header: "Chassi",
    accessor: "chassi",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Gravame",
    accessor: "numGravame",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Tipo",
    accessor: "rowTipo",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (rowTipo) => {
      return (
        <div>
          <span>
            {rowTipo.original.tipo == 1
              ? "Inclusão de Contrato"
              : rowTipo.original.tipo == 2
              ? "Alteração de Contrato"
              : rowTipo.original.tipo == 3
              ? "Averbação de Contrato"
              : rowTipo.original.tipo == 4
              ? "Alteração de Averbação"
              : ""}
          </span>
          <br />
        </div>
      );
    },
  },
  {
    Header: "Divergência",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    accessor: "rowDivergencia",
    Cell: (rowDivergencia) => <div>{rowDivergencia.original.divergencia == 0 ? "Sistema" : rowDivergencia.original.divergencia == 1 ? "Detran" : "Tipo divergente"}</div>,
  },
];

class InvoiceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramsUpload: false,
      pesquisando: false,
    };
  }

  componentDidMount() {
    this.props.onListLoad(api.Charging.registrosFaturamento(this.props.params.slug));
    if (this.props.params && this.props.params.sitConc && this.props.params.sitConc == 3) {
      this.props.onListLoadRegisterDivergency(api.Charging.registrosFaturamentoDivergente(this.props.params.slug));
    } else {
      this.props.onListLoadRegisterDivergency([]);
    }
    Promise.resolve(api.Charging.get(this.props.params.slug)).then((res) => {
      this.props.onChargingsLoad(res);
      Promise.resolve(api.Charging.getTiposAnexo(this.props.params.slug)).then((resp) => {
        this.props.onListAnexos(resp);
        this.verificaParamsUf();
      });
    });
  }

  async geraRelatorio() {
    try {
      this.setState({ pesquisando: true });
      toastr.info("Aguarde", "Gerando arquivo...", {
        timeOut: 0,
        component: () => {
          return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
        },
      });
      await api.Charging.criaRelatorio({
        dataInicio: this.props.fatura.periodoInicio,
        dataFim: this.props.fatura.periodoFim,
        idFinanceira: this.props.fatura.idFinanceira,
        uf: this.props.fatura.uf,
        idCobranca: this.props.fatura.id,
      });
      let resp = await api.Charging.getTiposAnexo(this.props.params.slug);
      this.props.onListAnexos(resp);
      this.verificaParamsUf();
      this.setState({ pesquisando: false });
      toastr.removeByType("info");
      toastr.success("Sucesso", "Documento Gerado com sucesso.");
    } catch (error) {
      this.handleError(e);
    }
  }

  handleError(error) {
    this.setState({ pesquisando: false });
    toastr.removeByType("info");

    if (error.message === "Unauthorized" || error.message === "jwt expired") {
      api.Auth.logout();
      this.props.onLogout();
    } else {
      toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
    }
  }

  handleClickDownload = (tipo) => (ev) => {
    ev.preventDefault();
    var props = this.props;
    Promise.resolve(api.Charging.getAnexoCobranca(this.props.params.slug, tipo))
      .then((response) => {
        fileDownload(response.body, response.headers["filename"], response.type);
      })
      .catch((e) => {
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  handleClickUpload = (filesToUpload, tipo) => {
    var types = ["application/pdf"];
    var alerta = "É permitido anexar somente PDF.";
    if (tipo == "RELATORIO" || tipo == "RELATORIO2") {
      types.push("application/vnd.ms-excel");
      types.push("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      alerta = "É permitido anexar somente PDF ou arquivos Excel.";
    }
    if (!filesToUpload[0] || types.indexOf(filesToUpload[0].type) < 0) {
      toastr.info("Atenção", alerta);
    } else {
      toastr.info("Aguarde", "Processando arquivo...", {
        timeOut: 0,
        component: () => {
          return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
        },
      });
      var props = this.props;
      var idCobranca = this.props.params.slug;
      Promise.resolve(api.Charging.sendAnexoCobranca(idCobranca, tipo, filesToUpload[0]))
        .then((response) => {
          Promise.resolve(api.Charging.getTiposAnexo(this.props.params.slug)).then((resp) => {
            this.props.onListAnexos(resp);
            this.verificaParamsUf();
          });
          toastr.removeByType("info");
          toastr.success("Sucesso", "Documento enviado.");
        })
        .catch(function (resp) {
          if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    }
  };

  verificaParamsUf = () => {
    if (this.props.fatura && this.props.fatura.paramsUf && this.props.fatura.paramsUf.lista2) {
      let contem = true;
      for (const value of this.props.fatura.paramsUf.lista2) {
        if (!this.props.tipoAnexos.filter((a) => a.tipo === value).length > 0) {
          contem = false;
        }
      }
      this.setState({ paramsUpload: contem });
    }
  };

  handleClickClose = () => (ev) => {
    this.props.onActionOpen({
      description: `Confirma o fechamento da cobrança?`,
      onSuccess: this.handleOnClose.bind(this),
    });
  };

  handleOnClose() {
    var props = this.props;
    var dados = {
      idCobranca: this.props.params.slug,
    };
    Promise.resolve(api.Charging.fechaCobranca(dados))
      .then((response) => {
        toastr.success("Sucesso", "Faturamento fechado com sucesso");
        this.props.onListLoad(api.Charging.registrosFaturamento(dados.idCobranca));
        this.props.onChargingsLoad(api.Charging.get(dados.idCobranca));
      })
      .catch((e) => {
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else if (e.response && e.response.text) {
          toastr.info("Aviso", JSON.parse(e.response.text).message.replaceAll(",", ",\n"));
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  handleClickEmail = () => (ev) => {
    var desc = "Confirma o envio do email para o responsável da financeira";
    if (this.props.fatura.paramsUf && this.props.fatura.paramsUf.lista2) {
      desc += ` com os seguintes documentos: ${this.props.fatura.paramsUf.lista.join(", ")}`;
    }
    this.props.onActionOpen({
      description: `${desc}?`,
      onSuccess: this.handleOnEmail.bind(this),
    });
  };

  handleOnEmail() {
    var props = this.props;
    var dados = {
      idCobranca: this.props.params.slug,
    };
    Promise.resolve(api.Charging.emailCobranca(dados))
      .then((response) => {
        toastr.success("Sucesso", "Email enviado com sucesso");
        //this.props.onListLoad(api.Charging.emailCobranca(dados.idCobranca));
        this.props.onChargingsLoad(api.Charging.get(dados.idCobranca));
      })
      .catch((e) => {
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else if (e.response && e.response.text) {
          toastr.info("Aviso", JSON.parse(e.response.text).message);
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  handleClickEmail2 = () => (ev) => {
    var desc = "Confirma o envio do email para o responsável da financeira com os seguintes documentos: ";
    desc += `${this.props.fatura.paramsUf.lista2.join(", ")}`;
    this.props.onActionOpen({
      description: `${desc}?`,
      onSuccess: this.handleOnEmail2.bind(this),
    });
  };

  handleOnEmail2() {
    var props = this.props;
    var dados = {
      idCobranca: this.props.params.slug,
    };
    Promise.resolve(api.Charging.emailCobranca2(dados))
      .then((response) => {
        toastr.success("Sucesso", "Email enviado com sucesso");
        //this.props.onListLoad(api.Charging.emailCobranca(dados.idCobranca));
        this.props.onChargingsLoad(api.Charging.get(dados.idCobranca));
      })
      .catch((e) => {
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else if (e.response && e.response.text) {
          toastr.info("Aviso", JSON.parse(e.response.text).message);
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  exportToXLSX = () => {
    var DataExcel = [];
    const headersExcell = [["CHASSI", "GRAVAME", "TIPO", "DIVERGÊNCIA"]];

    DataExcel = this.props.registrosDivergentes.map((row) => {
      return {
        chassi: row.chassi,
        numGravame: row.numGravame,
        tipo: row.tipo == 1 ? "Inclusão de Contrato" : row.tipo == 2 ? "Alteração de Contrato" : row.tipo == 3 ? "Averbação de Contrato" : row.tipo == 4 ? "Alteração de Averbação" : "",
        divergencia: row.divergencia == 0 ? "Sistema" : row.divergencia == 1 ? "Detran" : "Tipo divergente",
      };
    });

    var ws = XLSX.utils.aoa_to_sheet(headersExcell);
    XLSX.utils.sheet_add_json(ws, DataExcel, {
      skipHeader: true,
      origin: "A2",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Resultado");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `Registros-divergentes-${this.props.fatura.razaoSocial}.xlsx`);
  };

  render() {
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Gestão Financeira" />
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools">
                    {_.findIndex(this.props.currentUser.perfis, function (perfil) {
                      return perfil === "ALIAS";
                    }) === -1 && (
                      <span>
                        {this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "DAE_DARE").length > 0 && (
                          <Link className="btn btn-default" onClick={this.handleClickDownload("DAE_DARE")}>
                            <i className="fas fa-download" /> DAE/DARE
                          </Link>
                        )}
                        {this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "OFICIO_COBRANCA").length > 0 && (
                          <Link className="btn btn-default" onClick={this.handleClickDownload("OFICIO_COBRANCA")}>
                            <i className="fas fa-download" /> Ofício Cobrança
                          </Link>
                        )}
                        {this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "RELATORIO").length > 0 && (
                          <Link className="btn btn-default" onClick={this.handleClickDownload("RELATORIO")}>
                            <i className="fas fa-download" /> Relatório
                          </Link>
                        )}
                        {/* {this.props.fatura && this.props.fatura.uf === "SC" && <span class="linha-vertical"></span>} */}

                        {this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "DAE_DARE2").length > 0 && (
                          <Link className="btn btn-default" onClick={this.handleClickDownload("DAE_DARE2")}>
                            <i className="fas fa-download" /> DAE/DARE 2
                          </Link>
                        )}
                        {this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "RELATORIO2").length > 0 && (
                          <Link className="btn btn-default" onClick={this.handleClickDownload("RELATORIO2")}>
                            <i className="fas fa-download" /> Relatório 2
                          </Link>
                        )}
                        {this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "NFE").length > 0 && (
                          <Link className="btn btn-default" onClick={this.handleClickDownload("NFE")}>
                            <i className="fas fa-download" /> NFe
                          </Link>
                        )}
                        {this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "BOLETO_NFE").length > 0 && (
                          <Link className="btn btn-default" onClick={this.handleClickDownload("BOLETO_NFE")}>
                            <i className="fas fa-download" /> Boleto NFe
                          </Link>
                        )}
                        {this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "NOTA_DEBITO").length > 0 && (
                          <Link className="btn btn-default" onClick={this.handleClickDownload("NOTA_DEBITO")}>
                            <i className="fas fa-download" /> Nt. Débito
                          </Link>
                        )}
                        {this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "BOLETO_ND").length > 0 && (
                          <Link className="btn btn-default" onClick={this.handleClickDownload("BOLETO_ND")}>
                            <i className="fas fa-download" /> Boleto Nt. Débito
                          </Link>
                        )}
                      </span>
                    )}
                    {_.findIndex(this.props.currentUser.perfis, function (perfil) {
                      return perfil === "ALIAS";
                    }) > -1 &&
                      !this.state.pesquisando && (
                        <span>
                          <div class="dropdown">
                            <button class={this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "DAE_DARE").length > 0 ? "btn-success btn" : "btn btn-default"}>DAE/DARE</button>
                            <div class="dropdown-content" style={{ minWidth: "-moz-available" }}>
                              <Dropzone
                                accept="application/pdf"
                                name="file"
                                className="dropzone-blank fallback"
                                multiple={false}
                                onDrop={(filesToUpload, e) => this.handleClickUpload(filesToUpload, "DAE_DARE")}
                              >
                                <Link className="btn btn-xs">
                                  <i className="fas fa-upload" /> Anexar
                                </Link>
                              </Dropzone>
                              {this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "DAE_DARE").length > 0 && (
                                <Link className="btn btn-xs" onClick={this.handleClickDownload("DAE_DARE")}>
                                  <i className="fas fa-download" /> Baixar
                                </Link>
                              )}
                            </div>
                          </div>
                          <div class="dropdown">
                            <button class={this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "OFICIO_COBRANCA").length > 0 ? "btn-success btn" : "btn btn-default"}>
                              Ofício Cobrança
                            </button>
                            <div class="dropdown-content" style={{ minWidth: "-moz-available" }}>
                              <Dropzone
                                accept="application/pdf"
                                name="file"
                                className="dropzone-blank fallback"
                                multiple={false}
                                onDrop={(filesToUpload, e) => this.handleClickUpload(filesToUpload, "OFICIO_COBRANCA")}
                              >
                                <Link className="btn btn-xs">
                                  <i className="fas fa-upload" /> Anexar
                                </Link>
                              </Dropzone>
                              {this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "OFICIO_COBRANCA").length > 0 && (
                                <Link className="btn btn-xs" onClick={this.handleClickDownload("OFICIO_COBRANCA")}>
                                  <i className="fas fa-download" /> Baixar
                                </Link>
                              )}
                            </div>
                          </div>
                          <div class="dropdown">
                            <button class={this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "RELATORIO").length > 0 ? "btn-success btn" : "btn btn-default"}>Relatório</button>
                            <div class="dropdown-content" style={{ minWidth: "-moz-available" }}>
                              <Link className="btn btn-xs" onClick={() => this.geraRelatorio()}>
                                <i className="fas fa-upload" /> Gerar/Regerar
                              </Link>
                              {this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "RELATORIO").length > 0 && (
                                <Link className="btn btn-xs" onClick={this.handleClickDownload("RELATORIO")}>
                                  <i className="fas fa-download" /> Baixar
                                </Link>
                              )}
                            </div>
                          </div>
                          {/* {this.props.fatura && this.props.fatura.uf === "SC" && (
                          <span>
                            {this.props.fatura.status === 3 && (
                              <Link className="btn btn-success" onClick={this.handleClickEmail()}>
                                E-mail 1
                              </Link>
                            )}
                            <span class="linha-vertical"></span>
                            <div class="dropdown">
                              <button class={this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "DAE_DARE2").length > 0 ? "btn-success btn" : "btn btn-default"}>
                                DAE/DARE 2
                              </button>
                              <div class="dropdown-content">
                                <Dropzone
                                  accept="application/pdf"
                                  name="file"
                                  className="dropzone-blank fallback"
                                  multiple={false}
                                  onDrop={(filesToUpload, e) => this.handleClickUpload(filesToUpload, "DAE_DARE2")}
                                >
                                  <Link className="btn btn-xs">
                                    <i className="fas fa-upload" /> Anexar
                                  </Link>
                                </Dropzone>
                                {this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "DAE_DARE2").length > 0 && (
                                  <Link className="btn btn-xs" onClick={this.handleClickDownload("DAE_DARE2")}>
                                    <i className="fas fa-download" /> Baixar
                                  </Link>
                                )}
                              </div>
                            </div>
                            <div class="dropdown">
                              <button class={this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "RELATORIO2").length > 0 ? "btn-success btn" : "btn btn-default"}>
                                Relatório 2
                              </button>
                              <div class="dropdown-content">
                                <Dropzone
                                  accept="application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  name="file"
                                  className="dropzone-blank fallback"
                                  multiple={false}
                                  onDrop={(filesToUpload, e) => this.handleClickUpload(filesToUpload, "RELATORIO2")}
                                >
                                  <Link className="btn btn-xs">
                                    <i className="fas fa-upload" /> Anexar
                                  </Link>
                                </Dropzone>
                                {this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "RELATORIO2").length > 0 && (
                                  <Link className="btn btn-xs" onClick={this.handleClickDownload("RELATORIO2")}>
                                    <i className="fas fa-download" /> Baixar
                                  </Link>
                                )}
                              </div>
                            </div>
                          </span>
                        )} */}

                          <div class="dropdown">
                            <button class={this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "NFE").length > 0 ? "btn-success btn" : "btn btn-default"}>NFe</button>
                            <div class="dropdown-content" style={{ minWidth: "-moz-available" }}>
                              <Dropzone
                                accept="application/pdf"
                                name="file"
                                className="dropzone-blank fallback"
                                multiple={false}
                                onDrop={(filesToUpload, e) => this.handleClickUpload(filesToUpload, "NFE")}
                              >
                                <Link className="btn btn-xs">
                                  <i className="fas fa-upload" /> Anexar
                                </Link>
                              </Dropzone>
                              {this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "NFE").length > 0 && (
                                <Link className="btn btn-xs" onClick={this.handleClickDownload("NFE")}>
                                  <i className="fas fa-download" /> Baixar
                                </Link>
                              )}
                            </div>
                          </div>
                          <div class="dropdown">
                            <button class={this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "BOLETO_NFE").length > 0 ? "btn-success btn" : "btn btn-default"}>Boleto NFe</button>
                            <div class="dropdown-content" style={{ minWidth: "-moz-available" }}>
                              <Dropzone
                                accept="application/pdf"
                                name="file"
                                className="dropzone-blank fallback"
                                multiple={false}
                                onDrop={(filesToUpload, e) => this.handleClickUpload(filesToUpload, "BOLETO_NFE")}
                              >
                                <Link className="btn btn-xs">
                                  <i className="fas fa-upload" /> Anexar
                                </Link>
                              </Dropzone>
                              {this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "BOLETO_NFE").length > 0 && (
                                <Link className="btn btn-xs" onClick={this.handleClickDownload("BOLETO_NFE")}>
                                  <i className="fas fa-download" /> Baixar
                                </Link>
                              )}
                            </div>
                          </div>
                          <div class="dropdown">
                            <button class={this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "NOTA_DEBITO").length > 0 ? "btn-success btn" : "btn btn-default"}>
                              Nt. Débito
                            </button>
                            <div class="dropdown-content" style={{ minWidth: "-moz-available" }}>
                              <Dropzone
                                accept="application/pdf"
                                name="file"
                                className="dropzone-blank fallback"
                                multiple={false}
                                onDrop={(filesToUpload, e) => this.handleClickUpload(filesToUpload, "NOTA_DEBITO")}
                              >
                                <Link className="btn btn-xs">
                                  <i className="fas fa-upload" /> Anexar
                                </Link>
                              </Dropzone>
                              {this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "NOTA_DEBITO").length > 0 && (
                                <Link className="btn btn-xs" onClick={this.handleClickDownload("NOTA_DEBITO")}>
                                  <i className="fas fa-download" /> Baixar
                                </Link>
                              )}
                            </div>
                          </div>
                          <div class="dropdown">
                            <button class={this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "BOLETO_ND").length > 0 ? "btn-success btn" : "btn btn-default"}>
                              Boleto Nt. Débito
                            </button>
                            <div class="dropdown-content" style={{ minWidth: "-moz-available" }}>
                              <Dropzone
                                accept="application/pdf"
                                name="file"
                                className="dropzone-blank fallback"
                                multiple={false}
                                onDrop={(filesToUpload, e) => this.handleClickUpload(filesToUpload, "BOLETO_ND")}
                              >
                                <Link className="btn btn-xs">
                                  <i className="fas fa-upload" /> Anexar
                                </Link>
                              </Dropzone>
                              {this.props.tipoAnexos && this.props.tipoAnexos.filter((a) => a.tipo === "BOLETO_ND").length > 0 && (
                                <Link className="btn btn-xs" onClick={this.handleClickDownload("BOLETO_ND")}>
                                  <i className="fas fa-download" /> Baixar
                                </Link>
                              )}
                            </div>
                          </div>

                          {this.props.fatura && this.props.fatura.status === 1 && (
                            <Link className="btn btn-success" onClick={this.handleClickClose()}>
                              Fechar
                            </Link>
                          )}
                          {this.props.fatura && this.props.fatura.status === 3 && (
                            <Link className="btn btn-success" onClick={this.handleClickEmail()}>
                              E-mail
                            </Link>
                          )}

                          {/* <Link className="btn btn-success" onClick={this.handleClickEmail2()}>
                          E-mail 2
                        </Link> */}
                        </span>
                      )}
                    <Link className="btn btn-info" onClick={browserHistory.goBack}>
                      {" "}
                      Voltar
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="hpanel">
                <div className="panel-heading">Dados consolidados da fatura</div>
                <div className="panel-body">
                  <div className="row">
                    {this.props.registrosDivergentes && this.props.registrosDivergentes.length > 0 ? (
                      <>
                        <div className="col-md-6 text-center">
                          <div className="small">
                            <i className="fa fa-bolt"></i> Total de registros
                          </div>
                          <div>
                            <h1 className="font-extra-bold m-t-xl m-b-xs">{this.props.fatura ? this.props.fatura.quantidade : 0}</h1>
                            <small>Registros / Averbações</small>
                          </div>
                        </div>
                        <div className="col-md-6 text-center">
                          <div className="small">
                            <i className="fa fa-bolt"></i> Total de divergências
                          </div>
                          <div>
                            <h1 className="font-extra-bold m-t-xl m-b-xs">{this.props.registrosDivergentes.length}</h1>
                            <small>Registros / Averbações</small>
                          </div>
                        </div>
                        <div className="col-md-12 text-center m-t-xl">
                          <i className="fas fa-clock"></i> Período: {this.props.fatura ? MaskDate.TO_BR(this.props.fatura.periodoInicio) + " a " + MaskDate.TO_BR(this.props.fatura.periodoFim) : ""}
                        </div>
                      </>
                    ) : (
                      <div className="col-md-12 text-center">
                        <div className="small">
                          <i className="fa fa-bolt"></i> Total de registros
                        </div>
                        <div>
                          <h1 className="font-extra-bold m-t-xl m-b-xs">{this.props.fatura ? this.props.fatura.quantidade : 0}</h1>
                          <small>Registros / Averbações</small>
                        </div>
                        <div className=" m-t-xl">
                          <i className="fas fa-clock"></i> Período: {this.props.fatura ? MaskDate.TO_BR(this.props.fatura.periodoInicio) + " a " + MaskDate.TO_BR(this.props.fatura.periodoFim) : ""}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hpanel">
                <div className="panel-heading">&nbsp;</div>
                <div className="panel-body">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <div className="small">
                        <i className="fas fa-dollar-sign"></i> Valor total
                      </div>
                      <div>
                        <h1 className="font-extra-bold m-t-xl m-b-xs">{this.props.fatura ? MaskDecimal.TO_BRL(this.props.fatura.valorTotal) : 0}</h1>
                        <small>
                          Status Faturamento:{" "}
                          {this.props.fatura ? (this.props.fatura.status === 1 ? "ABERTO" : this.props.fatura.status === 2 ? "PAGO" : this.props.fatura.status === 3 ? "FECHADO" : "Outro") : ""}
                          {this.props.params && this.props.params.sitConc && (
                            <>
                              <br />
                              Status Conciliação:{" "}
                              {this.props.params.sitConc == 0
                                ? "AGUARDANDO"
                                : this.props.params.sitConc == 1
                                ? "SEM DADOS DETRAN"
                                : this.props.params.sitConc == 2
                                ? "CONCILIADO"
                                : this.props.params.sitConc == 3
                                ? "INCONSISTENTE"
                                : "NÃO POSSUÍ"}
                              ,
                            </>
                          )}
                        </small>
                      </div>
                      <div className=" m-t-md">Financeira: {this.props.fatura ? this.props.fatura.razaoSocial : ""}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {this.props.registrosDivergentes && this.props.registrosDivergentes.length > 0 && (
              <div className="col-md-12">
                <div className="hpanel">
                  <div className="panel-body">
                    <Link className="btn btn-default float-right" onClick={() => this.exportToXLSX()} title="Gerar Excel">
                      {" "}
                      <SvgIcon size={20} icon={fileExcelO} />
                    </Link>
                    <p>
                      <strong>Registros Divergentes</strong>
                    </p>
                    <div className="table-responsive">
                      <ReactTable
                        className="-striped -highlight"
                        data={this.props.registrosDivergentes}
                        previousText="Anterior"
                        nextText="Próximo"
                        loadingText="Carregando..."
                        noDataText="Nenhum registro encontrado"
                        pageText="página"
                        ofText="de"
                        rowsText="linhas"
                        columns={columnsDivergency}
                        defaultPageSize={10}
                        showPageSizeOptions={false}
                        minRows={1}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-12">
              <div className="hpanel">
                <div className="panel-body">
                  <p>
                    <strong>Registros</strong>
                  </p>
                  <div className="table-responsive">
                    <ReactTable
                      className="-striped -highlight"
                      data={this.props.registrosFatura}
                      previousText="Anterior"
                      nextText="Próximo"
                      loadingText="Carregando..."
                      noDataText="Nenhum registro encontrado"
                      pageText="página"
                      ofText="de"
                      rowsText="linhas"
                      columns={columns}
                      defaultPageSize={10}
                      showPageSizeOptions={false}
                      minRows={1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!this.props.currentUser.idFinanceira && this.props.fatura && this.props.fatura.logAuditoria && (
            <div className="row">
              <div className="col-lg-12">
                <div className="hpanel">
                  <div className="panel-body">
                    <p>
                      <strong>Historico</strong>
                    </p>
                    <div className="table-responsive">
                      <ReactTable
                        className="-striped -highlight"
                        data={this.props.fatura.logAuditoria}
                        previousText="Anterior"
                        nextText="Próximo"
                        loadingText="Carregando..."
                        noDataText="Nenhum registro encontrado"
                        pageText="página"
                        ofText="de"
                        rowsText="linhas"
                        columns={[
                          {
                            Header: "Nome",
                            accessor: "nome",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                          },
                          {
                            Header: "Data",
                            accessor: "row",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            Cell: (row) => <div>{MaskDate.TO_BR_DATETIME(row.original.data)}</div>,
                          },
                          {
                            Header: "Evento",
                            accessor: "eventoFormat",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            Cell: (row) => <div>{row.original.tipoEvento == "fechar_cobranca" ? "Fechamento Cobrança" : "Envio Email Cobrança"}</div>,
                          },
                          {
                            Header: "IP",
                            accessor: "ip",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                          },
                        ]}
                        defaultPageSize={10}
                        showPageSizeOptions={false}
                        minRows={1}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoad: (payload) => dispatch({ type: INVOICE_DETAIL_INVOICES_LOAD, payload }),
  onChargingsLoad: (payload) => dispatch({ type: INVOICE_DETAIL_CHARGING_LOAD, payload }),
  onLogout: () => dispatch({ type: LOGOUT }),
  onListAnexos: (payload) => dispatch({ type: INVOICE_ANEXOS_LIST_LOAD, payload }),
  onActionOpen: (payload) => dispatch({ type: CONFIRM_ACTION_OPEN, payload }),
  onListLoadRegisterDivergency: (payload) => dispatch({ type: INVOICE_DETAIL_INVOICES_DIVERGENCY, payload }),
});

const mapStateToProps = (state) => ({
  ...state.invoice,
  currentUser: state.common.currentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails);
